<template>
  <div class="w-work-wrap">
    <div class="w-work-href">
      <div class="w-crumbs-href">
        <router-link
          tag="span"
          class="link-item"
          :to="{ path: '/' }"
        >工作台</router-link>
        <i class="el-icon-arrow-right"></i>
        <router-link
          tag="span"
          class="link-item"
          :to="{ path: '/implement' }"
        >招商实施管理</router-link>
        <i class="el-icon-arrow-right"></i>
        <router-link
          v-if="$route.meta.title === '品牌招商管理详情' || $route.meta.title === '铺位招商详情'"
          tag="span"
          class="link-item"
          :to="{ path: $route.meta.title === '品牌招商管理详情' ? '/investmentFollowManage' : '/berthInvestmentManage' }"
        >{{$route.meta.title === '品牌招商管理详情' ? '品牌招商管理' : '铺位招商管理'}}</router-link>
        <i
          v-if="$route.meta.title === '品牌招商管理详情' || $route.meta.title === '铺位招商详情'"
          class="el-icon-arrow-right"
        ></i>
        <span
          class="w-white"
          v-if="$route.meta.title !== '项目招商简报'"
        >{{$route.meta.title}}</span>
        <span
          class="w-white"
          v-if="$route.meta.title === '项目招商简报'"
        >{{([0, 1].indexOf(user.level) !== -1) || user.isadmin === 0?'项目招商简报':'招商工作简报'}}</span>
      </div>
      <div
        class="select-right"
        v-show="!isShow.includes(routePath)"
      >
        <div class="w-input-sty project-select">
          <el-select
            popper-class="w-block-select-down yellow-select-panel"
            class="input-solid"
            v-model="filtroProject"
            size="small"
            placeholder="请选择"
            filterable
            style="width: 260px;"
            @change="changeProject"
          >
            <el-option
              v-for="item in projectOptions"
              :key="item.id"
              :label="item.projectName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div
      class="w-work-tabs"
      :class="[positonTop ? 'positonTop' : '']"
    >
      <div
        class="w-work-modular-wrap cursor"
        @click="clickDrawer()"
      >
        <span class="icon-side-hide"></span>招商实施管理
      </div>
      <ul class="tab-ul">
        <li
          @click="toPath('/implement')"
          :class="[routePath === '/implement' ? 'router-link-exact-active' : '']"
        >
          <p>招商进度管控</p>
        </li>
        <li
          @click="toPath('/visualization')"
          :class="[routePath === '/visualization' ? 'router-link-exact-active' : '']"
        >
          <p>招商可视管理</p>
        </li>
        <!-- <li
          @click="toPath('/shopsSinged')"
          :class="[routePath === '/shopsSinged' ? 'router-link-exact-active' : '']"
        >
          <p>商铺签约信息</p>
        </li> -->
        <li
          @click="toPath('/berthInvestmentManage')"
          :class="[routePath === '/berthInvestmentManage' || routePath === '/berthInvestmentManageDetail' ? 'router-link-exact-active' : '']"
        >
          <p>铺位招商管理</p>
        </li>
        <li
          @click="toPath('/investmentFollowManage')"
          :class="[routePath === '/investmentFollowManage' || routePath === '/investmentFollowDetail' ? 'router-link-exact-active' : '']"
        >
          <p>品牌招商管理</p>
        </li>
        <router-link
          active-class="active"
          tag="li"
          :to="{ path: '/merchantReports' }"
          :class="[routePath === '/merchantReports' || routePath === '/merchantReportsDetail' ? 'router-link-exact-active' : '']"
        >
          <p>{{([0, 1].indexOf(user.level) !== -1) || user.isadmin === 0?'项目招商简报':'招商工作简报'}}</p>
        </router-link>
        <!-- <router-link
          active-class="active"
          tag="li"
          :to="{ path: '/merchantsSet' }"
        >
          <p>招商实施设置</p>
        </router-link> -->
      </ul>
    </div>
    <el-drawer
      :size="isPx"
      :with-header="false"
      :visible.sync="drawer"
      direction="ltr"
      ref="drawer"
    >
      <Sidebar></Sidebar>
    </el-drawer>
    <div v-if="filtroProject > 0">
      <router-view
        class="w-work-content"
        :projectId='filtroProject'
        :projectName='projectName'
      />
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Sidebar from '@/components/Sidebar.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    Sidebar
  },
  data () {
    return {
      filtroProject: 0,
      projectOptions: [],
      drawer: false,
      routePath: '',
      isPx: '516px',
      positonTop: false,
      isOpened: false,
      projectName: '',
      isShow: ['/investmentFollowDetail', '/berthInvestmentManageDetail']
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    '$route.path': function (newVal, oldVal) {
      this.routePath = newVal
      this.drawer = false
    }
  },
  created () {
    this.routePath = this.$route.path
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    // const clientWidth = document.documentElement.clientWidth
    // if (clientWidth > 1500) {
    //   this.isPx = '516px'
    // } else {
    //   this.isPx = '448px'
    // }
    this.getDataProjList()
    window.addEventListener('scroll', this.handleScroll)
    if (this.$route.query.type) {
      this.isOpened = true
    } else {
      this.isOpened = false
    }
  },
  methods: {
    changeProject (val) {
      const params = {
        projectId: val
      }
      const obj = this.projectOptions.find(item => {
        return item.id === val
      })
      this.projectName = obj.projectName
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getDataProjList () { // select项目列表
      this.loading = true
      this.axios.post(api.getBerthProjectList, {
      }).then(res => {
        this.projectOptions = res.data.data
        this.getProjectIdFunc().then((res) => {
          this.filtroProject = res.data.data.projectId
          this.projectName = res.data.data.projectName
          this.$router.push({
            query: Object.assign({ projectId: this.filtroProject }, this.$route.query, { _: +new Date() })
          })
        })
        this.loading = false
      }, rej => {
        console.log(rej)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    getProjectIdFunc () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getProjectId)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error(''))
            }
          })
      })
    },
    toPath (path) {
      localStorage.removeItem('saveScreen')
      this.$router.push({
        path: path
      })
    },
    handleScroll (e) {
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (scrollTop > 88) {
        this.positonTop = true
      } else {
        this.positonTop = false
      }
    },
    clickDrawer () {
      this.drawer = true
    },
    planAnalysis () {
      this.isOpened = !this.isOpened
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-work-wrap
  .w-work-href
    height 64px
    display flex
    align-items center
    padding 0 24px
    padding-left 204px
    .select-right
      margin-left auto
  .w-work-tabs
    position absolute
    left 0
    top 140px
    width 180px
    height calc(100% - 122px)
    z-index 11
    .w-work-modular-wrap
      height 34px
      line-height 33px
      font-size 16px
      font-weight 500
      color #FFFFFF
      background-color #272930
      // box-shadow 32px 0px 13px 0px rgba(18, 19, 25, 0.5)
      .icon-side-hide
        width 40px
        text-align center
        color #fff
        background-color rgba(255, 255, 255, 0.1)
        border-right 1px solid rgba(255, 255, 255, 0.2)
        margin-right 20px
    .tab-ul
      height calc(100% - 55px)
      background-color #23252B
      margin-top 13px
      padding-top 5px
      >li
        position relative
        height 40px
        line-height 40px
        // text-align center
        padding-left 20px
        cursor pointer
        box-sizing border-box
        border-left 3px solid #23252B
        margin 5px 0
        .el-icon-arrow-down
          top 22px
          right 15px
          font-size 12px
          color #fff
        &.router-link-exact-active
          background-color rgba(255, 255, 255, 0.07)
          border-left 3px solid #FFA134
          span, p
            color #ffffff
        &:hover
          background-color rgba(255, 255, 255, 0.07)
          border-left 3px solid #FFA134
          span, p
            color #fff
        span
          font-size 24px
          margin-top 26px
          margin-bottom 5px
          color #A4A4A6
        p
          height 14px
          font-size 14px
          font-weight 400
          color #A5A5A7
.w-work-wrap
  .positonTop
    position fixed
    left 0
    top 8px !important
    height 100%
    z-index 11
    ul
      min-height 100%
      background-color #23252B
      margin-top 10px
.submenu-menu
  ul li
    height 40px
    line-height 40px
    color rgba(165, 165, 167, 1)
    font-size 14px
    padding-left 30px
    cursor pointer
    border-left 3px solid transparent
    &:hover
      background-color rgba(255, 255, 255, 0.07)
      border-left 3px solid #FFA134
    &.router-link-exact-active
      background-color rgba(255, 255, 255, 0.07)
      border-left 3px solid #FFA134
      color #fff
.w-work-wrap .w-work-tabs .tab-ul .isChecked
  p
    color #fff
  .el-icon-arrow-down
    transform rotateZ(180deg)
</style>
